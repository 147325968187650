import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Label,
} from 'recharts';

import CustomDropDown from '../../../common/dropDown/CustomDropDown';
import CustomTooltip from '../chart/CustomTooltip';
import { borderStyle } from '../commonStyle/commonLeaveStyle';
import '../styles.css';
import ForecastedLeaveDistributionHeader from './ForecastedLeaveDistributionHeader';

const DISTRIBUTION_TYPE = Object.freeze([
    { key: 'leads', label: 'Leads' },
    { key: 'partners', label: 'Partners' },
]);

const ForecastedLeave = ({
    selectedYear,
    partners,
    leads,
    leadsAndPartnersPicker,
    forecastedLeaveData,
    chartData,
    isDataLoaderOpen,
}) => {
    const [selectedDistributionType, setDistributionType] = useState(
        DISTRIBUTION_TYPE[0]
    );
    const currentYear = new Date().getFullYear();
    const displayXAxisLabel =
        selectedYear === currentYear ? 'Forecasted Leaves' : `Actual Leaves`;

    const [filterData, setFilterData] = useState();
    const modifyData = () => {
        const arr = [];
        chartData.map((data, index) => {
            if (data?.property === 'tillTwenty') {
                const newData = { ...data };
                (newData.fill = '#12B76A'),
                    (newData.label = 'Till 20'),
                    arr.push(newData);
            } else if (data?.property === 'twentyOneToTwentyFive') {
                const newData = { ...data };
                (newData.fill = '#FF8C21'),
                    (newData.label = '21 - 25'),
                    arr.push(newData);
            } else if (data?.property === 'moreThanTwentyFive') {
                const newData = { ...data };
                (newData.fill = '#E30000'),
                    (newData.label = 'Above 25'),
                    arr.push(newData);
            }
        });
        return arr;
    };

    useEffect(() => {
        if (selectedDistributionType?.key === 'leads') {
            leadsAndPartnersPicker('LEAD');
        } else {
            leadsAndPartnersPicker('PARTNER');
        }
    }, [selectedDistributionType?.key]);

    useEffect(() => {
        if (filterData) {
            forecastedLeaveData(filterData);
        }
    }, [filterData]);

    useEffect(() => {
        setFilterData(null);
    }, [selectedDistributionType]);

    return (
        <Box {...borderStyle}>
            <ForecastedLeaveDistributionHeader
                selectedYear={selectedYear}
                distributionType={DISTRIBUTION_TYPE}
                selectedDistributionType={selectedDistributionType}
                setDistributionType={setDistributionType}
            />
            <Box style={{ display: isDataLoaderOpen ? 'none' : 'block' }}>
                <CustomDropDown
                    data={
                        selectedDistributionType === DISTRIBUTION_TYPE[0]
                            ? leads
                            : partners
                    }
                    placeholder={
                        selectedDistributionType === DISTRIBUTION_TYPE[0]
                            ? 'All Leads'
                            : 'All Partners'
                    }
                    onChange={selected => setFilterData(selected)}
                    distributionType={selectedDistributionType}
                />
            </Box>
            <br />
            {isDataLoaderOpen ? (
                <Box w={500}>
                    <Box className="loader" />
                </Box>
            ) : (
                <Box>
                    <BarChart
                        width={500}
                        height={400}
                        data={modifyData()}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 20,
                            bottom: 100,
                        }}
                    >
                        <CartesianGrid stroke="#ccc" vertical={false} />
                        <XAxis dataKey="label">
                            <Label
                                angle={360}
                                position="center"
                                style={{ textAnchor: 'middle' }}
                                dy={40}
                            >
                                {displayXAxisLabel}
                            </Label>
                        </XAxis>
                        <YAxis>
                            <Label
                                angle={270}
                                position="left"
                                style={{ textAnchor: 'middle' }}
                            >
                                Employee Count
                            </Label>
                        </YAxis>
                        <Bar dataKey="employeeCount" fill="red" />

                        <Tooltip
                            content={<CustomTooltip name={'ForecastedLeave'} />}
                            cursor={{ fill: 'transparent' }}
                        />
                    </BarChart>
                </Box>
            )}
        </Box>
    );
};

ForecastedLeave.propTypes = {
    forecastedLeaveData: PropTypes.any,
    leads: PropTypes.array,
    partners: PropTypes.array,
    leadsAndPartnersPicker: PropTypes.any,
    chartData: PropTypes.any,
    selectedYear: PropTypes.any,
    isDataLoaderOpen: PropTypes.any,
};

export default ForecastedLeave;
