import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CustomTooltip = ({ active, payload, name, tooltipData }) => {
    if (active && payload && payload.length) {
        const color = payload[0].payload.fill;
        return (
            <Box
                className="custom-tooltip"
                style={{
                    borderColor: color,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: 'auto',
                    bottom: '30px',
                    zIndex: 10,
                }}
            >
                {name === 'ForecastedLeave' ? (
                    <Box>
                        <Text
                            style={{
                                color,
                                textAlign: 'center',
                            }}
                        >{`${payload[0].payload.employeeCount}`}</Text>
                        <Text style={{ color }}>Employees</Text>
                    </Box>
                ) : (
                    <Text style={{ color }}>
                        {`${
                            payload[0].name === 'Auto Approved Leave Days'
                                ? tooltipData?.totalAutoApproveLeaveRatio
                                : tooltipData?.totalApproveLeaveRatio
                        }%`}
                    </Text>
                )}
            </Box>
        );
    }

    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.any,
    payload: PropTypes.array,
    name: PropTypes.string,
    tooltipData: PropTypes.any,
};

export default CustomTooltip;
