import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import {
    Avatar,
    Box,
    Flex,
    Image,
    Text,
    useToast,
    WrapItem,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { ReactComponent as DownArrow } from '../../../assets/icons/arrow-down.svg';
import info from '../../../assets/icons/info.svg';
import CommonPagination from '../../../common/CommonPagination';
import { buildLeavesPath } from '../../../constants/pathNameConstants';
import LeaveManager from '../../../service/leave-manager';
import '../styles.css';
import Filter from './Filter';

const API_URL = process.env.REACT_APP_SERVER_URL;

const columns = [
    {
        id: 1,
        name: 'Lead',
        selector: row => {
            const profilePictureUrl = row.profilePictureUrl;
            const name = row.name;
            return (
                <Flex gap="4px">
                    <WrapItem>
                        <Avatar
                            w="32px"
                            h="32px"
                            name={name}
                            src={`${API_URL}${profilePictureUrl}`}
                        />
                    </WrapItem>
                    <Text alignSelf="center">{name}</Text>
                </Flex>
            );
        },
    },
    {
        id: 2,
        name: (
            <Box>
                <a
                    data-tooltip-variant="light"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Count of requests - approved and rejected."
                    data-tooltip-place="top"
                >
                    <span>Total Leave Requests</span>{' '}
                    <img className="tooltip-info" src={info} alt="info" />
                </a>
                <Tooltip id="my-tooltip" className="lightTooltip" />
            </Box>
        ),
        selector: row => row.totalLeaveRequestsReceived,
    },
    {
        id: 3,
        name: 'Auto-approved leave requests',
        selector: row => row.totalLeaveAutoApproved,
    },
    {
        id: 4,
        name: 'Auto-approve Leave Percentage',
        selector: row =>
            `${Math.ceil(row.totalLeaveAutoApprovePercentage)}${' '}%`,
    },
];
const AutoApprovedLeaveTabPanel = ({
    approvedType,
    dataType,
    partners,
    leads,
    selectedYear,
}) => {
    let { page, size } = useParams();
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();

    const [selectedLeads, setSelectedLeads] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [data, setData] = useState();
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const toast = useToast();
    const [selectedDownloadType, setSelectedDownloadType] = useState(null);

    const handleSelectedItem = selectedData => {
        if (selectedData.type === 'leads') {
            const leadIds = selectedData.items.map(lead => lead.employeeId);
            setSelectedLeads(leadIds);
        } else if (selectedData.type === 'partners') {
            setSelectedPartners(selectedData.items);
        }
    };
    const handleDownloadSelect = type => {
        setSelectedDownloadType(type);
        fetchDataDownload(type);
        setTimeout(() => {
            setSelectedDownloadType(null);
        }, 2000);
    };
    useEffect(() => {
        fetchAutomaticallyApprovedLeaves();
    }, [selectedLeads, selectedYear, dataType, page, size]);

    async function fetchAutomaticallyApprovedLeaves() {
        setDataLoaderOpen(true);

        try {
            const leadsId = selectedLeads;
            const year = selectedYear || currentYear;

            const response = await LeaveManager.getAutomaticallyApprovedLeaves(
                year,
                leadsId,
                page,
                size
            );
            setData(response);
            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);

            toast({
                title: 'Failed to fetch Auto approved leave',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
    }
    async function fetchDataDownload(type) {
        setDataLoaderOpen(true);
        try {
            const leadsId = selectedLeads?.length ? selectedLeads : [];
            const year = selectedYear || currentYear;
            const format = type;
            const isAutoApprovedData = dataType;
            const response = await LeaveManager.getDataDownload(
                year,
                format,
                leadsId,
                null,
                null,
                isAutoApprovedData
            );

            const blob = new Blob([response], {
                type: format === 'pdf' ? 'application/pdf' : 'text/csv',
            });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `leave-report-${year}.${format}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);

            toast({
                title: 'Failed to fetch Auto approved leave',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }
    function setPageNumber(pageNumber) {
        page = pageNumber;
        navigate(buildLeavesPath(page, size));
    }

    return (
        <Box className="App">
            <Filter
                approvedType={approvedType}
                partners={partners}
                leads={leads}
                selectedItem={handleSelectedItem}
                selectDownload={handleDownloadSelect}
            />
            {isDataLoaderOpen ? (
                <Box className="loader" />
            ) : (
                <DataTable
                    columns={columns}
                    data={data?.data}
                    sortIcon={<DownArrow />}
                />
            )}

            <CommonPagination
                page={page}
                size={size}
                totalPages={data?.meta?.totalPages}
                setPageNo={setPageNumber}
            />
        </Box>
    );
};

AutoApprovedLeaveTabPanel.propTypes = {
    approvedType: PropTypes.string,
    partners: PropTypes.any,
    leads: PropTypes.any,
    dataType: PropTypes.any,
    selectedYear: PropTypes.number,
};

export default AutoApprovedLeaveTabPanel;
